<template>
  <div>
    <map-view></map-view>
  </div>
</template>

<script>
export default {
  components: {
    MapView: () => import('../../components/Parcel/MapView.vue')
  },
  mounted() {
    this.$store.commit('viewMode', 'map');
  }
};
</script>

<style>
</style>
